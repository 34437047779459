import React from "react"
import { Flex, Box } from "rebass"
import { Footer, SEO, Layout, Container, Hero } from "@components"
import StepColumn from "../components/pages/feature/stepColumn"
import Summary from "../components/pages/feature/summary"
import { ExternalLink } from "@fragments"
import { download } from "@constants/urls.js"

const formatLDJsonFAQ = (questions) => {
  const mainEntity = questions.map((question) => ({
    "@type": "Question",
    name: question.question,
    acceptedAnswer: {
      "@type": "Answer",
      text: question.answer,
    },
  }))

  const ldJson = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity,
  }

  return JSON.stringify(ldJson, null, 2)
}

const productFeaturePage = ({
  pageContext: {
    title,
    metaDescription,
    videoPath,
    youtubeId,
    steps,
    summaryTitle,
    summaryDescription,
    faq,
    intro,
    content,
    slug,
  },
}) => {
  return (
    <>
      <Layout hidePreheaderCTA>
        <SEO
          title={title}
          description={metaDescription}
          ldJsonFAQ={formatLDJsonFAQ(faq)}
          canonical={`https://dynobase.dev/${slug}/`}
        />
        <div
          style={{
            margin: "auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Hero title={title} />
          <Container>
            <Box width={1} p={4} backgroundColor={"#f8f8f8"} mt={3}>
              <b>Prerequisite:</b> &nbsp;{" "}
              <ExternalLink
                to={download}
                className="dynobase-download-button"
              >
                Download Dynobase
              </ExternalLink>{" "}
              (free to try) in order to follow the steps below
            </Box>
            {intro && (
              <div
                style={{ marginTop: "32px", fontSize: "16px" }}
                dangerouslySetInnerHTML={{ __html: intro }}
              />
            )}
            {youtubeId ? (
              <iframe
                width="100%"
                height="500"
                src={`https://www.youtube.com/embed/${youtubeId}`}
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            ) : (
              <video
                autoplay
                loop
                muted
                controls
                style={{
                  boxShadow: "3px 4px 13px 5px hsla(0, 0%, 0%, 0.24)",
                  borderRadius: "4px",
                  width: "100%",
                  marginTop: "44px",
                  marginBottom: "44px",
                }}
              >
                <source src={videoPath} type="video/mp4" />
              </video>
            )}
            <Flex flexWrap="wrap">
              {steps.map((step, index) => (
                <StepColumn
                  title={step.title}
                  description={step.description}
                  stepNumber={index + 1}
                  totalSteps={steps.length}
                />
              ))}
            </Flex>
            {faq && <hr style={{ margin: "20px 40%" }} />}
            {content && (
              <Box my={"40px"}>
                <h2>{content.headline}</h2>
                <p>{content.content}</p>
              </Box>
            )}
            {faq && (
              <h2 style={{ marginTop: "40px", marginBottom: "40px" }}>
                Frequently Asked Questions
              </h2>
            )}
            {faq &&
              faq.map((point) => (
                <div>
                  <h3 style={{ marginBottom: "0.6em", lineHeight: "1.5" }}>
                    {point.question}
                  </h3>
                  <p
                    style={{ marginBottom: "2.2em" }}
                    dangerouslySetInnerHTML={{ __html: point.answer }}
                  />
                </div>
              ))}
            {summaryDescription && (
              <Summary title={summaryTitle} description={summaryDescription} />
            )}
          </Container>
          <Footer />
        </div>
      </Layout>
    </>
  )
}

export default productFeaturePage
