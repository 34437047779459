import React from "react"
import { Box } from "rebass"

const Summary = ({ title, description }) => {
  return (
    <Box width={1} p={4} backgroundColor={"#f8f8f8"}>
      <div
        style={{
          fontFamily: "helvetica, sans-serif",
          fontSize: "23px",
          fontWeight: "600",
          fontStretch: "normal",
          fontStyle: "normal",
          lineHeight: "normal",
          letterSpacing: "normal",
          color: "#535353",
          marginBottom: "32px",
        }}
      >
        {title}
      </div>
      <p
        style={{
          fontFamily: "helvetica, sans-serif",
          fontSize: "16px",
          fontWeight: "normal",
          fontStretch: "normal",
          fontStyle: "normal",
          lineHeight: "1.5",
          letterSpacing: "normal",
          color: "#535353",
          marginBottom: 10,
        }}
      >
        {description}
      </p>
    </Box>
  )
}

export default Summary
