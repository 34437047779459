import React from "react"
import { Box } from "rebass"

const StepColumn = ({ stepNumber, totalSteps, title, description }) => {
  return (
    <Box width={[1, 1 / totalSteps]} p={2}>
      <div
        style={{
          fontFamily: "helvetica, sans-serif",
          fontSize: "144px",
          fontWeight: "400",
          fontStretch: "normal",
          fontStyle: "normal",
          lineHeight: "1.25",
          letterSpacing: "normal",
          color: "#f0f0f0",
        }}
      >
        {stepNumber}
      </div>
      <div
        style={{
          fontFamily: "helvetica, sans-serif",
          fontSize: "18px",
          fontWeight: "600",
          fontStretch: "normal",
          fontStyle: "normal",
          lineHeight: "1.67",
          letterSpacing: "normal",
          color: "#666666",
        }}
      >
        {title}
      </div>
      <p
        style={{
          fontFamily: "helvetica, sans-serif",
          fontSize: "14px",
          fontWeight: "500",
          fontStretch: "normal",
          fontStyle: "normal",
          lineHeight: "2.5",
          letterSpacing: "normal",
          color: "#666",
        }}
      >
        {description}
      </p>
    </Box>
  )
}

export default StepColumn
